@media screen and (max) {
    
}
@media screen and (max-width:1000px) {
    body{
         background: none !important;
    }
    .login-page-wrapper{
        justify-content: center !important;
        display: block !important;
        padding: 15px !important;
        height: 100%;
        background-image:none !important;
        .login-form-wrapper{
            margin-top: 0 !important;
            display: block !important;
            padding-right: 0px !important;
            .copyright-text{
                bottom: 0 !important;
                right: auto !important;
                position: relative !important;
                display: none !important;
            }
            .social-button-wrapper{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 50px;
                padding-left: 20px;
                padding-right: 20px;
                .ant-btn-primary{
                    background: $colorGreen !important;
                    border-color: $colorGreen !important;
                    padding: 0 !important;
                }
            }
        }
        .login-form-inner{
            margin: 0 auto;
        }
    } 
    .success-button{
        &.ant-btn-primary {
            color: $colorWhite !important;
            background: $colorGreen !important;
            border-color: $colorGreen !important;
            border-radius: 7px;
            height: 44px;
        }
    }
    
}

.login-page-wrapper{
    background-color: $bgColor;
    background-image: url('../../images/login-bottom-bg.png');
    padding: 75px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom right;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    .login-side-wrapper{
        align-items: flex-start;
        position: relative;
        padding: 12rem 0rem;
        .play-store-wrapper{
            position: absolute;
            bottom: 10px;
            text-align: left;
            p{
                color: $colorDark;
                font-size: 10px;
            }
        }
    }
    .login-form-wrapper{
        width: auto !important;
        position: relative;
        align-items: flex-start;
        margin-top: 8rem;
        padding-right: 60px;
        .loginLogo{
            width: auto;
        }
        .copyright-text{
            margin-bottom: 0;
            font-size: 10px;
            position: absolute;
            bottom: 35px;
            right: 60px;
        }
        .login-form-inner{
            background: $colorWhite;
            padding: 45px 40px;
            border-radius: 40px;
            -webkit-box-shadow: 2px 3px 14px 7px #0000003d; 
            box-shadow: 2px 3px 14px 7px #0000003d;
            width: 400px;
        }
        .success-button{
            border-radius: 50px;
            margin-bottom: 15px;
        }
        .ant-checkbox-wrapper{
            margin-left: 20px;
        }
        .social-button-wrapper{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 50px;
            padding-left: 20px;
            padding-right: 20px;
            .ant-btn-primary{
                background: $colorGreen;
                border-color: $colorGreen;
            }
        }
        .ant-form-item-control-input-content{
            border-bottom: none !important;
        }
        .ant-form-item-control-input{
            padding: 5px 10px;
            background: $colorWhite;
            border-radius: 50px;
            padding: 2px 10px;
            border: 1px solid #efefef !important;
            border-radius: 50px;
            -webkit-box-shadow: 2px 3px 14px 7px #00000011; 
            box-shadow: 2px 3px 14px 7px #00000011;
            svg{
                color: #707070;
            }
        }
        .ant-form-item-explain-error{
            margin-top: 5px;
            margin-bottom: 5px;
            padding-left: 15px;
        }
    }
    .login-banner-wrapper{
        padding-left: 20%;
        img{
            max-width: 100%;
            height: 100%;
        }
    }
    .login-title{
        p{
            font-size: 24px;
            color: $colorDark;
            font-weight: 400;
        }
        margin-bottom: 15px;
    }
    
}

@media screen and (max-width:1030px) {
    .login-banner-wrapper{
        padding-left: 0% !important;
        padding-right: 10% !important;
    }
    .login-form-wrapper{
        padding-right: 0% !important;
    }
}



