.ant-layout-header{
    height: auto;
    line-height: normal;
}
.institute-name{
    font-size: 18px;
    color: $colorGreen;
    font-weight: 600;
}
.ant-layout-header{
    background: $bgColor;
}
// .anticon-menu-fold{
//     &.trigger{
//         margin-top: 30px;
//     }
// }
.anticon-menu-unfold{
    &.trigger{
        margin-top: 30px;
    }
}
.ins-info-wrapper{
    padding-left: 20px;
    padding-right: 15px;
    // margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .en-number{
        padding-right: 5px;
    }
    .acdemic-year{
        padding-left: 5px;
    }
}
.ant-layout-header{
    display: flex;
    align-items: center;
    justify-content: left;
    width: 100%;
    background: #FCFCFC;
}