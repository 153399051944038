.ant-layout-content{
    margin-top: 25px;
    height: calc(100vh - 175px);
    overflow: auto;
    margin-left: 15px;
    margin-right: 15px;
    .ant-card{
        border: none;
        .ant-card-head{
            border-width: 10px;
            border-color: $bgColor;
            .ant-card-head-wrapper{
                .ant-card-head-title{
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 1.8;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    display: block;
                }
            }
        }
        .info-wrapper{
            .title{
                font-size: 14px;
                font-weight: 500;
                line-height: 2.4;
            }
            .separator{
                font-size: 14px;
                line-height: 2.4;
            }
            .details{
                font-size: 14px;
                line-height: 2.4;
            }
            .ant-avatar{
                border: 1px solid $colorGreen;
                margin-bottom: 30px;
            }
        }
    }
    
}

.custon-form-wrapper{
    .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
        line-height: 44px;
    }
    .ant-select .ant-select-selector {
        height: 44px !important;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector{
        border-radius: 7px;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector{
        border-color: #ABA7A7;
    }
    .ant-input{
        height: 44px !important;
        line-height: 44px;
        border-color: #ABA7A7;
        border-radius: 7px;
    }
}
.ant-form input[type='file'] {
    border-color: #ABA7A7;
    border-radius: 7px;
}
.success-button{
    &.ant-btn-primary {
        color: #fff;
        background: $colorGreen !important;
        border-color: $colorGreen !important;
        border-radius: 7px;
        height: 44px;
    } &:disabled{ 
        background: $colorGreenDisable !important;
        border-color: $colorGreenDisable !important;
        color: #adadad !important;
    }
} 
.danger-button{
    &.ant-btn-primary {
        color: #fff;
        background: $colorRed;
        border-color: $colorRed;
        border-radius: 7px;
        height: 44px;
    }
}
.ant-pagination{
    margin-bottom: 0px;
}
.payment-instruction-wrapper{
    margin-left: 25px;
    span{
        display: block;
        font-size: 12px;
    }
    .text-highlight{
        color: $colorRed;
    }
}
.ant-table-tbody > tr.ant-table-row-selected > td {
    background: rgba(77,151,6,0.3);
    border-color: rgba(0, 0, 0, 0.03);
}
.ant-table-row.ant-table-row-selected:hover{
    background: rgba(77,151,6,0.3);
}
.payable-wrapper{
    .payment-input{
        input{
            background: $colorWhite;
            border: none;
        }
        span{
            display: block;
            margin-bottom: 5px;
        }
    }
    .success-button{
        height: auto;
    }
}
.custom-table{
    .ant-table-thead > tr > th{
        background: #F1F6F1;
    }
}
.custom-inner-card{
    .ant-card-head{
        border-width: 0px !important;
        border-color: transparent !important;
        padding: 0;
    }
    .ant-card-head-wrapper{
        border: 1px solid $rightSideBarBg;
        padding-left: 15px;
        padding-right: 15px;
        border-radius: 10px;
    }
}
.custom-card-view{
    &.ant-card{
        box-shadow: none !important;
    }
    .ant-card-body{
        padding: 0 !important;        
    }

}